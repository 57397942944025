<template>
  <b-modal
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="existujiciObhodnyPripad-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Existující obchodní případy</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <div v-for="(zakazka, indx) in existujiciInfo" :key="zakazka.kodZakazky">
          <hr v-if="indx" class="pt-2">
          <div class="shoda">Shoda {{ zakazka.score }}%</div>
          <div v-html="getZakaznikName(zakazka)"></div>
          <div v-html="getCityKraj(zakazka)"></div>
          <div v-html="getAddrrTelEmail(zakazka)"></div>
          <div v-html="getObchodniZastupceName(zakazka)"></div>
        </div>

        <div class="d-flex justify-content-center w-100>">
          <b-button
              type="submit"
              class="mt-4 mb-2"
              variant="primary"
              @click="canBeSaved"
          >
            Nejedná se o totožné případy
          </b-button>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {MESSAGES, KRAJ} from "@/constants/form.constants";
import axios from "axios";
import _ from "lodash";
import commonMixin from "@/mixins/common.mixin.ts";

export default {
  computed: {
    MESSAGES() {
      return MESSAGES
    }
  },
  props: {
    show: {type: Boolean},
    newObchodPripadData: {type: Object},
  },
  mixins: [commonMixin],
  data() {
    return {
      KRAJ,
      isLoading: false,
      showLocal: false,
      existujiciInfo: [],
      lists: {
        obchodniZastupce: [],
        obchodniZastupceFull: [],
        obchodniZastupceObjById: {},
      },
    }
  },

  methods: {
    async getExistujiciObhodnyPripadList() {
      const url = `/obchod/existujiciObhodnyPripad`;
      const {data: {data}} = await axios.post(url, {data: {...this.newObchodPripadData, getList: true}});
      this.existujiciInfo = data?.existujiciObhodnyPripadInfo || [];
    },
    getZakaznikName(zakazka) {
      const personasInfo = _.get(zakazka, 'obchod.personasInfo.0');
      if (personasInfo) {
        let {titleBeforeName, name, lastName, titleAfterName} = personasInfo;
        titleBeforeName = titleBeforeName ? `${titleBeforeName}, ` : '';
        titleAfterName = titleAfterName ? `, ${titleAfterName}` : '';
        return `${titleBeforeName}<b>${lastName}, ${name}</b>${titleAfterName}`;
      }
      return `<b>${zakazka.clientLastName}, ${zakazka.clientName}</b>`;
    },
    getCityKraj(zakazka) {
      return `<b>${zakazka.cityInstallation}, ${this.getKrajName(zakazka?.kraj, this.KRAJ)}</b>`
    },
    getAddrrTelEmail(zakazka) {
      const personasInfo = _.get(zakazka, 'obchod.personasInfo.0');
      const addressInstallation = this.getFullAddress(zakazka);
      const tel = _.get(personasInfo, 'phone', '-');
      const email = _.get(personasInfo, 'email', '-');
      return [`<b>${addressInstallation}</b>`, ` tel. <b>${tel}</b>`, `email <b>${email}</b>`].join(', ');
    },
    async getObchodniZastupceList() {
      const url = `/user/getObchodniZastupceList`;
      const {data} = await axios.get(url, { });
      this.lists.obchodniZastupce = data?.data?.activeOzUsersNames || [];
      this.lists.obchodniZastupceFull = data?.data?.activeOzUsersInfo || [];
      this.lists.obchodniZastupceFull.forEach(user => {
        this.lists.obchodniZastupceObjById[user.id] = user.fullName;
      })
    },
    getObchodniZastupceName(zakazka) {
      const importedObchodniZastupceName = _.get(zakazka, 'obchodniZastupceParseComment', '');
      const ObchodniZastupceName = _.get(this, `lists.obchodniZastupceObjById.${zakazka?.obchodniZastupceId}`, '') || importedObchodniZastupceName;
      return `Obchodní zástupce - <b>${ObchodniZastupceName}</b>`;
    },
    canBeSaved() {
      this.$emit('can-be-saved');
      this.$emit('close-modal');
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getObchodniZastupceList();
    await this.getExistujiciObhodnyPripadList();
    this.isLoading = false;
    this.showLocal = this.show;
  }
}
</script>

<style scoped>
  .shoda {
    font-size: 17px;
    font-weight: bold;
    color: mediumblue;
  }
</style>