<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="smlouvaDilo-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Smlouva / faktury k zakázce č. {{ formData.kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <div class="errMsg-color fs-15px text-center font-weight-bold pb-2" v-if="isKodZakazkyExist">
              Případ s takovým kódem již existuje
            </div>
            <div class="d-flex">
              <b-form-group class="label-1 w-50" label="Smlouva (objednávka) nebo faktura" label-for="smlouvaNeboFaktura">
                <ValidationProvider rules="required" name="'Smlouva (objednávka) nebo faktura'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                      name="smlouvaNeboFaktura"
                      v-model="formData.verzeSoD"
                      :options="lists.verzeSoD"
                      class="form-select form-select-sm"
                      size="sm"
                      :title="errors[0]"
                      :state="getState(classes, formData.verzeSoD)"
                      @change="changeSmlouvaNeboFaktura"
                  />
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="label-1 w-50 pl-3" label="Cena FVE před slevou (bez DPH)" label-for="cenaPredSlevou">
                <ValidationProvider :rules="getCenaPredSlevouRules()" name="'Cena FVE před slevou (bez DPH)'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="cenaPredSlevou"
                      currency-symbol-position="suffix"
                      thousand-separator=" "
                      decimal-separator=","
                      :precision="2"
                      currency="Kč"
                      class="form-control form-control-sm"
                      :class="getClassByValidatorState(classes)"
                      v-model="formData.cenaPredSlevou"
                      :empty-value="0"
                      :title="errors[0]"
                      @input="changeCenaPredSlevou()"
                  />
                </ValidationProvider>
              </b-form-group>
            </div>

            <div class="d-flex pt-2">
              <div class="w-50 d-flex">
                <span class="text-nowrap label-1 pt-1">Poskytnuta sleva</span>
                <b-form-group class="label-1 pl-2" label-for="poskytnutaSlevaPerc">
                  <ValidationProvider rules="required|double|min_value:0.00" name="'Poskytnuta sleva %'" v-slot="{ classes, errors }">
                    <vue-numeric
                        id="poskytnutaSlevaPerc"
                        currency-symbol-position="suffix"
                        decimal-separator=","
                        :precision="2"
                        currency="%"
                        class="form-control form-control-sm"
                        :class="getClassByValidatorState(classes)"
                        v-model="formData.poskytnutaSlevaPerc"
                        :empty-value="0"
                        :title="errors[0]"
                        @input="calcPoskytnutaSlevaKc($event)"
                    />
                  </ValidationProvider>
                </b-form-group>
              </div>
              <b-form-group class="label-1 w-50 pl-3" label-for="poskytnutaSlevaKc">
                <ValidationProvider rules="required|double|min_value:0.00" name="'Poskytnuta sleva Kč'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="poskytnutaSlevaKc"
                      currency-symbol-position="suffix"
                      thousand-separator=" "
                      decimal-separator=","
                      :precision="2"
                      currency="Kč"
                      class="form-control form-control-sm"
                      :class="getClassByValidatorState(classes)"
                      v-model="formData.poskytnutaSlevaKc"
                      :empty-value="0"
                      :title="errors[0]"
                      @input="calcPoskytnutaSlevaPerc($event)"
                  />
                </ValidationProvider>
              </b-form-group>
            </div>

            <div class="d-flex">
              <b-form-group class="label-1 d-inline w-50" label="Cena FVE po slevě (bez DPH)" label-for="cenaFVEPoSleve">
                <vue-numeric
                    id="cenaFVEPoSleve"
                    :value="formData.cenaFVEPoSleve"
                    currency-symbol-position="suffix"
                    thousand-separator=" "
                    decimal-separator=","
                    :precision="0"
                    currency="Kč"
                    class="form-control form-control-sm"
                    disabled
                    :empty-value="0"
                />
              </b-form-group>

              <b-form-group class="label-1 w-100px pl-3" label="DPH" label-for="dphFVE">
                <ValidationProvider rules="required" name="'DPH'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                      name="dphFVE"
                      v-model="formData.dphFVE"
                      :options="lists.dph"
                      class="form-select form-select-sm"
                      size="sm"
                      :title="errors[0]"
                      :state="getState(classes, formData.dphFVE)"
                  />
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="label-1 w-50 pl-3" label="Cena FVE celkem (vč. DPH)" label-for="cenaFVECelkem">
                <vue-numeric
                    id="cenaFVECelkem"
                    :value="formData.cenaFVECelkem"
                    currency-symbol-position="suffix"
                    thousand-separator=" "
                    decimal-separator=","
                    :precision="0"
                    currency="Kč"
                    class="form-control form-control-sm"
                    disabled
                    :empty-value="0"
                />
              </b-form-group>
            </div>

            <div class="pt-3">
              <b-form-checkbox
                  id="isNSE"
                  v-model="formData.isNSE"
                  name="isNSE"
                  class="d-inline-block text-right"
                  @change="changeNSE"
              >
                Nabíjecí stanice pro elektromobily (NSE)
              </b-form-checkbox>
            </div>

            <div v-if="formData.isNSE" class="d-flex pt-3">
              <b-form-group class="label-1 d-inline w-50" label="Cena NSE (bez DPH)" label-for="cenaNSE">
                <ValidationProvider rules="required|double|min_value:0.00" name="'Cena FVE celkem (vč. DPH)'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="cenaNSE"
                      currency-symbol-position="suffix"
                      thousand-separator=" "
                      decimal-separator=","
                      :precision="2"
                      currency="Kč"
                      class="form-control form-control-sm"
                      :class="getClassByValidatorState(classes)"
                      v-model="formData.cenaNSE"
                      :empty-value="0"
                      :title="errors[0]"
                      @input="changeCenaPredSlevou()"
                  />
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="label-1 w-100px pl-3" label="DPH" label-for="dphNSE">
                <ValidationProvider rules="required" name="'DPH'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                      name="dphNSE"
                      v-model="formData.dphNSE"
                      :options="lists.dph"
                      class="form-select form-select-sm"
                      size="sm"
                      :title="errors[0]"
                      :state="getState(classes, formData.dphNSE)"
                  />
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="label-1 w-50 pl-3" label="Cena NSE celkem (vč. DPH)" label-for="cenaNSECelkem">
                <vue-numeric
                    id="cenaNSECelkem"
                    :value="formData.cenaNSECelkem"
                    currency-symbol-position="suffix"
                    thousand-separator=" "
                    decimal-separator=","
                    :precision="0"
                    currency="Kč"
                    class="form-control form-control-sm"
                    disabled
                    :empty-value="0"
                />
              </b-form-group>
            </div>

            <hr>

            <div class="d-flex">
              <b-form-group class="label-1 w-50" label="Název díla" label-for="nazevDila">
                <b-form-input
                    id="nazevDila"
                    type="text"
                    size="sm"
                    v-model.trim="nazevDila"
                    disabled
                />
              </b-form-group>
              <b-form-group v-if="![PRIPAD_TYPES.ROZSIRENI, PRIPAD_TYPES.VICEPRACE].includes(formData.category)" class="label-1 w-170px pl-3 text-white" label="." label-for="power">
                <ValidationProvider rules="required|double|min_value:0.001|max_value:5000" name="'Power'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="power"
                      currency-symbol-position="suffix"
                      thousand-separator=" "
                      decimal-separator=","
                      :precision="3"
                      currency="kWp"
                      class="form-control form-control-sm w-120px"
                      :class="getClassByValidatorState(classes)"
                      v-model="formData.powerPlantCapacity"
                      :empty-value="0"
                      :title="errors[0]"
                  />
                </ValidationProvider>
              </b-form-group>
            </div>

            <div class="d-flex">
              <b-form-group class="label-1 d-inline w-50" label="Cena díla (bez DPH)" label-for="cenaDila">
                <vue-numeric
                    id="cenaDila"
                    :value="formData.cenaDila"
                    currency-symbol-position="suffix"
                    thousand-separator=" "
                    decimal-separator=","
                    :precision="0"
                    currency="Kč"
                    class="form-control form-control-sm"
                    disabled
                    :empty-value="0"
                />
              </b-form-group>

              <b-form-group class="label-1 w-170px pl-3" label="DPH" label-for="resDPH">
                <vue-numeric
                    id="resDPH"
                    :value="formData.resDPH"
                    currency-symbol-position="suffix"
                    thousand-separator=" "
                    decimal-separator=","
                    :precision="0"
                    currency="Kč"
                    class="form-control form-control-sm"
                    disabled
                    :empty-value="0"
                />
              </b-form-group>

              <b-form-group class="label-1 w-50 pl-3" label="Cena díla celkem (vč. DPH)" label-for="cenaDilaCelkem">
                <vue-numeric
                    id="cenaDilaCelkem"
                    :value="formData.cenaDilaCelkem"
                    currency-symbol-position="suffix"
                    thousand-separator=" "
                    decimal-separator=","
                    :precision="0"
                    currency="Kč"
                    class="form-control form-control-sm"
                    disabled
                    :empty-value="0"
                />
              </b-form-group>
            </div>

            <div class="d-flex">
              <b-form-group class="label-1 w-25" label="1.ZFA" label-for="ZFA1perc">
                <ValidationProvider rules="required|integer|between:0,100" name="'1.ZFA'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="ZFA1perc"
                      currency-symbol-position="suffix"
                      :precision="0"
                      currency="%"
                      class="form-control form-control-sm"
                      :class="getClassByValidatorState(classes)"
                      v-model="formData.ZFA1perc"
                      :empty-value="0"
                      :title="errors[0]"
                  />
                </ValidationProvider>
              </b-form-group>
              <b-form-group class="label-1 w-25 pl-3" label="2.ZFA" label-for="ZFA2perc">
                <ValidationProvider rules="required|integer|between:0,100" name="'2.ZFA'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="ZFA2perc"
                      currency-symbol-position="suffix"
                      :precision="0"
                      currency="%"
                      class="form-control form-control-sm"
                      :class="getClassByValidatorState(classes)"
                      v-model="formData.ZFA2perc"
                      :empty-value="0"
                      :title="errors[0]"
                  />
                </ValidationProvider>
              </b-form-group>
              <b-form-group class="label-1 w-25 pl-3" label="3.ZFA" label-for="ZFA3perc">
                <ValidationProvider rules="required|integer|between:0,100" name="'3.ZFA'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="ZFA3perc"
                      currency-symbol-position="suffix"
                      :precision="0"
                      currency="%"
                      class="form-control form-control-sm"
                      :class="getClassByValidatorState(classes)"
                      v-model="formData.ZFA3perc"
                      :empty-value="0"
                      :title="errors[0]"
                  />
                </ValidationProvider>
              </b-form-group>
              <b-form-group class="label-1 w-25 pl-3" label="Ostrá faktura" label-for="ostraFaktura">
                <ValidationProvider rules="required|integer|between:1,100" name="'Ostrá faktura'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="ostraFaktura"
                      currency-symbol-position="suffix"
                      :precision="0"
                      currency="%"
                      class="form-control form-control-sm"
                      v-model="formData.ostraFaktura"
                      :empty-value="0"
                      :class="getClassByValidatorState(classes)"
                      :title="errors[0]"
                  />
                </ValidationProvider>
              </b-form-group>
            </div>
            <div v-if="startValidation && isZFAError" class="text-center text-danger pb-1">Součet faktur musí být 100%</div>
            <div v-else-if="!formData.ostraFaktura" class="text-center text-danger pb-1">Ostrá faktura nesmí být nulová</div>

            <div class="d-flex pt-2 w-100">
              <div class="w-50">
                <span class="pr-2 pt-label bold pt-1">Doba plnění</span>
                <ValidationProvider rules="required|integer|between:1,9999" name="'Doba plnění'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                    id="terminDokonceniRealizace"
                    size="sm"
                    v-model="formData.terminDokonceniRealizace"
                    class="d-table-cell w-70px"
                    v-mask="'####'"
                    :title="errors[0]"
                    :state="getState(classes)"
                  />
                  <span class="bold pl-2">dní</span>
                </ValidationProvider>
              </div>
              <div class="w-50 text-right">
                <span class="pr-2 pt-label pt-1">Poznámka</span>
                  <b-form-input
                    id="poznamka1"
                    size="sm"
                    v-model="formData.poznamka1"
                    class="d-table-cell w-210px"
                    maxlength="50"
                  />
              </div>
            </div>

            <hr>

            <div class="d-flex">
              <div class="w-50 d-flex">
                <span class="text-nowrap pt-label pt-1">Provize OZ</span>
                <b-form-group class="label-1 pl-2" label-for="provizeOZ">
                  <vue-numeric
                      id="provizeOZ"
                      currency-symbol-position="suffix"
                      decimal-separator=","
                      :precision="2"
                      currency="%"
                      class="form-control form-control-sm w-80px"
                      v-model="formData.provizeOZ"
                      :empty-value="0"
                      @input="calcPoskytnutaSlevaKc($event)"
                  />
                </b-form-group>
                <b-form-group class="label-1 pl-3" label-for="provizeOZKc">
                  <vue-numeric
                      id="provizeOZKc"
                      currency-symbol-position="suffix"
                      thousand-separator=" "
                      decimal-separator=","
                      :precision="2"
                      currency="Kč"
                      class="form-control form-control-sm w-120px"
                      v-model="formData.provizeOZKc"
                      :empty-value="0"
                  />
                </b-form-group>
              </div>
              <div class="w-50 text-right">
                <span class="pr-2 pt-label pt-1">Poznámka</span>
                <b-form-input
                  id="poznamka2"
                  size="sm"
                  v-model="formData.poznamka2"
                  class="d-table-cell w-210px"
                  maxlength="50"
                />
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center w-100 pt-2">
              <div>
                <a v-if="isShowGenerateSmlouva" href="#" @click="generateSmlouvaK()" class="text-nowrap">
                  <b-icon-download class="pr-1" variant="info" font-scale="1.5" />
                  Smlouva (nepodepsaná)
                </a>
                <span v-else class="color-grey cursor-not-allowed text-nowrap">
                  <b-icon-download class="pr-1" variant="info" font-scale="1.5" />
                  Smlouva (nepodepsaná)
                </span>
              </div>
              <b-form-checkbox
                  id="podepsana"
                  v-model="formData.podepsana"
                  class="text-nowrap d-flex align-items-center"
                  @change="changePodepsana"
              >
                <div class="pt-1px label-1">
                  Podepsáno
                </div>
              </b-form-checkbox>
              <ValidationProvider class="pl-2" :rules="{required: !!formData.podepsana}" name="'Datum'" v-slot="{ classes, errors }">
                <b-form-datepicker
                    id="podepsanaDate-datepicker"
                    v-model="formData.podepsanaDate"
                    :start-weekday="1"
                    size="sm"
                    locale="cs"
                    :title="errors[0]"
                    :state="getState(classes)"
                    class="w-230px"
                />
              </ValidationProvider>
            </div>

            <div class="d-flex justify-content-center w-100>">
              <b-button type="submit" class="mt-4 mb-2" variant="primary">Uložit změny</b-button>
            </div>

          </b-form>
        </ValidationObserver>
      </div>
    </b-overlay>
  </b-modal>
</template>


<script>
import commonMixin from "@/mixins/common.mixin.ts";
import _ from "lodash";
import VueNumeric from 'vue-numeric'
import {MIME_TYPES, OBJECT_TYPE, PRIPAD_TYPES, VERZE_SOD, VERZE_SOD_TYPE} from "@/constants/form.constants";
import axios from "axios";
import saveAs from "save-as";

const today = new Date();
today.setHours(12, 0, 0, 0);

export default {
  components: {VueNumeric},
  props: {
    id: {type: String},
    kodZakazky: { type: String },
    show: { type: Boolean },
  },
  mixins: [commonMixin],
  computed: {
    isHaveAccess() {
      return this.$store.getters.isSmlouvaFakturaAllowed;
    },
    isZFAError() {
      return +this.formData.ZFA1perc + +this.formData.ZFA2perc + +this.formData.ZFA3perc + +this.formData.ostraFaktura !== 100;
    },
    isRoshireni() {
      return this.formData.category === PRIPAD_TYPES.ROZSIRENI;
    },
    isShowGenerateSmlouva() {
      return [OBJECT_TYPE.FIRM, OBJECT_TYPE.VS].includes(this.formData.object) && this.formData.verzeSoD === VERZE_SOD_TYPE.KONZULTACNI_SMLOUVA;
    }
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      startValidation: false,
      nazevDila: null,
      PRIPAD_TYPES: PRIPAD_TYPES,
      lists: {
        verzeSoD: VERZE_SOD,
        dph: [],
      },
      formData: {
        verzeSoD: null,
        cenaPredSlevou: 0,
        poskytnutaSlevaPerc: 0,
        poskytnutaSlevaKc: 0,
        powerPlantCapacity: 0,
        podepsana: null,
        dphFVE: 0,
        dphNSE: 0,
        cenaNSE: 0,
        podepsanaDate: null,
        provizeOZ: 0,
        provizeOZKc: 0,
        isNSE: null,
        cenaFVEPoSleve: 0,
        cenaFVECelkem: 0,
        cenaDila: 0,
        cenaDilaCelkem: 0,
        resDPH: 0,
        cenaNSECelkem: 0,
        ZFA1perc: 0,
        ZFA2perc: 0,
        ZFA3perc: 0,
        ostraFaktura: 0,
        category: null,
        kodZakazky: null,
        terminDokonceniRealizace: null,
        poznamka1: null,
        poznamka2: null,
      },
      isNoCalcPoskytnutaSlevaKc: false,
      isKodZakazkyExist: false,
    }
  },
  watch: {
    formData: {
      handler(newValue, oldValue) {
        if (newValue.verzeSoD !== oldValue.verzeSoD) {
          this.getNazevDilaText();
        }
        this.calcCenaFVEPoSleve();
        this.calcCenaFVECelkem();
        this.calcCenaNSECelkem();
        this.calcCenaDila();
        this.calcCenaDilaCelkem();
        this.calcResDPH();
      },
      deep: true
    }
  },
  methods: {
    calcCenaFVEPoSleve()  {
      if (_.isNull(this.formData?.poskytnutaSlevaKc)) this.formData.cenaFVEPoSleve = 0;

      const cenaPredSlevou = _.get(this, 'formData.cenaPredSlevou', 0);
      const poskytnutaSlevaKc = _.get(this, 'formData.poskytnutaSlevaKc', 0);
      this.formData.cenaFVEPoSleve = cenaPredSlevou - poskytnutaSlevaKc;
    },
    calcCenaFVECelkem() {
      if (_.isNull(this.formData.cenaFVEPoSleve)) this.formData.cenaFVECelkem = 0;

      const dphFVE = _.get(this, 'formData.dphFVE', 0);
      this.formData.cenaFVECelkem = Math.round(+this.formData.cenaFVEPoSleve * (dphFVE / 100 + 1)) || 0;
    },
    calcCenaNSECelkem() {
      if (_.isNull(this.formData.cenaNSE)) this.formData.cenaNSECelkem = 0;

      const cenaNSE = _.get(this, 'formData.cenaNSE', 0);
      const dphNSE = _.get(this, 'formData.dphNSE', 0);
      this.formData.cenaNSECelkem = Math.round(+cenaNSE * (+dphNSE / 100 + 1)) || 0;
    },
    calcCenaDila() {
      const cenaNSE = _.get(this, 'formData.cenaNSE', 0);
      this.formData.cenaDila = Math.round(+this.formData.cenaFVEPoSleve + +cenaNSE) || 0;
    },
    calcCenaDilaCelkem() {
      this.formData.cenaDilaCelkem = (+this.formData.cenaFVECelkem + +this.formData.cenaNSECelkem) || 0;
    },
    calcResDPH() {
      this.formData.resDPH = this.formData.cenaDilaCelkem - this.formData.cenaDila;
    },
    getCenaPredSlevouRules() {
      return this.isRoshireni && this.formData.cenaNSE > 0 ? '' : 'required|double|min_value:0.01';
    },
    changeKodZakazkySymbol(symbol) {
      const str = this.formData.kodZakazky;
      if (str < 8) return;

      const length = str.length;
      if (length === 9 && !["K", "P", "D"].includes(str[8])) {
        this.formData.kodZakazky = str.substring(0, 9) + symbol + str.substring(10);
      } else if (length === 10 && ["K", "P", "D"].includes(str[9])) {
        this.formData.kodZakazky = str.substring(0, 9) + symbol + str.substring(10);
      } else {
        this.formData.kodZakazky = str.substring(0, 8) + symbol + str.substring(9);
      }
    },
    async changeKodZakazky() {
      switch (this.formData.verzeSoD) {
        case VERZE_SOD_TYPE.KONZULTACNI_SMLOUVA:
          this.changeKodZakazkySymbol("K");
          break;
        case VERZE_SOD_TYPE.PROJEKCNI_SMLOUVA:
          this.changeKodZakazkySymbol("P");
          break;
        case VERZE_SOD_TYPE.REALIZACNI_SMLOUVA:
        case VERZE_SOD_TYPE.FAKTURA:
          this.changeKodZakazkySymbol("D");
          break;
      }

      await this.validateKodZakazkyOnExist();
    },
    async validateKodZakazkyOnExist() {
      const url = `/obchod/checkExistKodZakazky`;
      const { data = {} } = await axios.post(url, {kodZakazky: this.formData.kodZakazky, id: this.id});
      this.isKodZakazkyExist = data.data.isExist;
    },
    async changeSmlouvaNeboFaktura() {
      await this.changeKodZakazky();
      this.getNazevDilaText();
    },
    getNazevDilaText() {
      switch (this.formData.category) {
        case PRIPAD_TYPES.FVE:
          this.nazevDila = 'Fotovoltaická elektrárna o výkonu';
          break;
        case PRIPAD_TYPES.OHREV_VODY:
          this.nazevDila = 'Fotovoltaický ohřev vody o výkonu';
          break;
        case PRIPAD_TYPES.ROZSIRENI:
          this.nazevDila = 'Rozšíření fotovoltaické elektrárny';
          break;
        case PRIPAD_TYPES.VICEPRACE:
          this.nazevDila = 'Vícepráce';
          break;
      }
    },
    async onSubmit(isCloseModal = true) {
      try {
        this.startValidation = true;

        if (this.isZFAError || this.isKodZakazkyExist) {
          return;
        }

        const url = `/obchod/saveSmlouvaFakturaForm`;
        const {data} = await axios.post(url, { formData: this.formData, id: this.id });
        this.$snotify[data.status](data.data.message);
        this.startValidation = false;
        if (data.status === 'success') {
          this.$emit('form-saved')
          if (isCloseModal) this.$emit('close-modal');
        }
      } catch (e) {
        console.error('saveSmlouvaFakturaForm', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer(['DPH_LARGE']) || {};
        this.lists.dph = dataConst.DPH_LARGE;
      } catch (e) {
        console.error('getConstants', e);
      }
    },
    async getSavedData() {
      try {
        const url = `/obchod/getSmlouvaFakturaFormInfo`;
        const { data: {data} } = await axios.get(url, {params: {id: this.id}});
        const info = data?.info?.obchod || {};
        for (let key in info.smlouvaDilo) {
          if (_.isNull(info.smlouvaDilo[key])) delete info.smlouvaDilo[key];
        }
        this.formData = {...this.formData, ...info.smlouvaDilo, category: info.category, object: info.object};
        this.formData.dphFVE = this.formData.dphFVE ? this.formData.dphFVE : this.lists.dph[0].value;
        this.formData.dphNSE = this.formData.dphNSE ? this.formData.dphNSE : this.lists.dph[0].value;
        this.formData.kodZakazky = this.kodZakazky;
        this.formData.terminDokonceniRealizace = data?.info?.terminDokonceniRealizace;
      } catch (e) {
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
        console.error('getSavedData', e.response?.data)
      }
    },
    calcPoskytnutaSlevaKc(event) {
      if (_.isUndefined(event) || this.isNoCalcPoskytnutaSlevaKc) return;

      if (!this.formData.poskytnutaSlevaPerc) return;
      this.formData.poskytnutaSlevaKc = +(+this.formData.poskytnutaSlevaPerc * +this.formData.cenaPredSlevou / 100).toFixed(2) || 0;
    },
    calcPoskytnutaSlevaPerc(event) {
      if (_.isUndefined(event)) return;
      if (!this.formData.poskytnutaSlevaKc) return;

      const calculation = +(+this.formData.poskytnutaSlevaKc * 100 / +this.formData.cenaPredSlevou).toFixed(2) || 0
      this.formData.poskytnutaSlevaPerc = _.isFinite(calculation) ? calculation : 0;
    },
    changeCenaPredSlevou() {
      if (!this.formData.poskytnutaSlevaKc && !this.formData.poskytnutaSlevaPerc) return;
      this.calcPoskytnutaSlevaPerc(true);
    },
    changePodepsana() {
      this.formData.podepsanaDate = this.formData.podepsana ? today : null;
    },
    changeNSE() {
      if (!this.formData.isNSE) {
        this.formData.cenaNSE = null;
        this.formData.dphNSE = null;
      }
    },
    async generateSmlouvaK() {
      try {
        const valid = await this.$refs.form.validate();
        this.startValidation = true;
        if (!valid || this.isZFAError || this.isKodZakazkyExist) {
          this.$snotify['error']('Formulář obsahuje chyby');
          return;
        }

        await this.onSubmit(false);

        const fileName = `${this.formData.kodZakazky}_Smlouva o poradenské a konzultační činnosti (nepodepsáno).docx`;

        const url = `/generate/docx/smlouvaK`;
        const {data} = await axios({
          method: "get",
          url: url,
          params: { id: this.id },
          responseType: "blob",
        });
        const blob = new Blob([data], { type: `${ MIME_TYPES.docx };base64,` });
        this.$snotify['success']('Předvyplněná smlouva vygenerována');
        this.startValidation = false;
        saveAs(blob, fileName);
      } catch (e) {
        console.error('generateSmlouvaK', e);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    this.isNoCalcPoskytnutaSlevaKc = true;
    await this.getConstants();
    await this.getSavedData();
    this.calcPoskytnutaSlevaPerc(true);
    this.isLoading = false;
    this.showLocal = this.show;
    this.getNazevDilaText();
    setTimeout(() => {
      this.isNoCalcPoskytnutaSlevaKc = false;
    }, 1000);
  },

}
</script>

<style scoped>
  label {
    padding-right: 10px;
    font-size: 15px;
  }
  p {
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 15px;
  }
</style>
