<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="dodatkeSmlouve-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Dodatky ke smlouvě č. {{ kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">



          </b-form>
        </ValidationObserver>
      </div>
    </b-overlay>
  </b-modal>
</template>


<script>
import commonMixin from "@/mixins/common.mixin.ts";

export default {
  components: {},
  props: {
    id: {type: String},
    kodZakazky: { type: String, default: '' },
    show: { type: Boolean },
  },
  mixins: [commonMixin],
  computed: {
    isHaveAccess() {
      return this.$store.getters.isDodatkySmlouveAllowed;
    },
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      formData: {

      },
    }
  },
  methods: {
    async onSubmit() {
      try {
        // if (this.isZFAError || this.isKodZakazkyExist) {
        //   return;
        // }
        //
        // const url = `/obchod/saveSmlouvaFakturaForm`;
        // const {data} = await Axios.post(url, { formData: this.formData, id: this.id });
        // this.$snotify[data.status](data.data.message);
        // if (data.status === 'success') {
        //   this.$emit('form-saved')
        //   this.$emit('close-modal');
        // }
      } catch (e) {
        console.error('saveDodatkeSmlouveForm', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getSavedData() {
      try {
        // const url = `/obchod/getSmlouvaFakturaFormInfo`;
        // const { data: {data} } = await Axios.get(url, {params: {id: this.id}});
        // const info = data?.info?.obchod || {};
        // for (let key in info.smlouvaDilo) {
        //   if (_.isNull(info.smlouvaDilo[key])) delete info.smlouvaDilo[key];
        // }
        // this.formData = {...this.formData, ...info.smlouvaDilo, category: info.category, object: info.object};
        // this.formData.dphFVE = this.formData.dphFVE ? this.formData.dphFVE : this.lists.dph[0].value;
        // this.formData.dphNSE = this.formData.dphNSE ? this.formData.dphNSE : this.lists.dph[0].value;
        // this.formData.kodZakazky = this.kodZakazky;
        // this.formData.terminDokonceniRealizace = data?.info?.terminDokonceniRealizace;
      } catch (e) {
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
        console.error('getSavedData', e.response?.data)
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    // await this.getSavedData();
    this.isLoading = false;
    this.showLocal = this.show;
  },

}
</script>

<style scoped>

</style>
