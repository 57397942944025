<template>
  <b-form-group>
    <b-form-checkbox
        v-model="allSelected"
        :indeterminate="indeterminate"
        @change="toggleAll"
    >
      {{ allSelected ? 'Odznačit vše' : 'Vše' }}
    </b-form-checkbox>
    <b-row>
      <b-col v-for="(column, index) in columns" :key="index">
        <b-form-checkbox-group
            :id="'checkbox-group-' + index"
            v-model="selectedElements"
            :name="'flavour-' + index"
            :class="[!index ? 'pl-3' : '']"
        >
          <div v-for="(element, idx) in elementsInColumns[index]" :key="idx">
            <b-form-checkbox
                :value="element.value"
                class="text-nowrap"
            >
              {{ element.text }}
            </b-form-checkbox>
          </div>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
  </b-form-group>
</template>

<script>
export default {
  name: 'checkBoxMultiSubComponents',
  props: {
    elements: [],
    filterName: { type: String, default: '' },
    number: { type: Number, default: 2 },
    selected: [],
  },
  data() {
    return {
      allSelected: false,
      indeterminate: false,
      selectedElements: [...this.selected],
    }
  },
  computed : {
    columns() {
      const totalColumns = Math.ceil((this.elements.length + 1) / this.number);
      return new Array(totalColumns).fill('');
    },
    elementsInColumns() {
      const elementsInColumns = [];
      const elementsCopy = [...this.elements];
      while (elementsCopy.length > 0) {
        elementsInColumns.push(elementsCopy.splice(0, this.number));
      }
      return elementsInColumns;
    },
  },
  methods: {
    toggleAll(checked) {
      this.selectedElements = checked ? this.elements.map(elem => elem.value) : []
    },
    setAllSelectedIndeterminate(newVal) {
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.elements.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
  watch: {
    selectedElements(newVal) {
      this.setAllSelectedIndeterminate(newVal);
      this.$emit('check-box-multi-selected', this.filterName, newVal);
    },
  },
  mounted() {
    this.setAllSelectedIndeterminate(this.selectedElements);
  },
}
</script>
<style scoped>

</style>