<template>
  <b-modal
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="multifilter-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Multifiltr</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="w-100 d-flex justify-content-between">
        <span class="pr-3 pt-label">Zvolit kritérium filtrování</span>
        <div class="d-flex">
          <b-form-select
              name="multifilters"
              v-model="multiFilterForSelect"
              :options="filteredMultiFilters"
              class="form-select form-select-sm d-inline-block w-270px text-black"
              size="sm"
          />
          <b-icon-plus-circle
              :variant="multiFilterForSelect ? 'primary' : 'disabled'"
              class="pl-3 pr-2 fs-14px pt-7px va--0_25"
              :class="[multiFilterForSelect ? 'cursor-pointer' : 'cursor-not-allowed color-disabled']"
              @click="addFilter"
          />
          <a href="#"
             class="pl-3 pr-5 pt-label"
             :class="[multiFilterForSelect ? 'cursor-pointer' : 'cursor-not-allowed color-disabled']"
             @click="addFilter"
          >
            přidat podmínku
          </a>
        </div>
        <b-button size="sm" variant="info" @click="acceptMultiFilter" class="ml-3">
          Vysledek filtrovaní
        </b-button>
      </div>

      <div class="pt-3 d-flex flex-column">
        <b-card v-if="isShowFilter(FILTER_NAMES.NAZEV_V_ADRESARE)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.NAZEV_V_ADRESARE)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Název adresáře v zakázkách</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.NAZEV_V_ADRESARE, true)"
            />
          </div>
          <div class="d-flex pb-1">
            <b-form-input
                id="nazevVAdresari"
                type="text"
                size="sm"
                v-model.trim="localMultiFilters.nazevVAdresari"
                class="d-inline-block w-350px text-black"
            />
          </div>
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.STAV)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.STAV)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Stav obchodního případu</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.STAV)"
            />
          </div>
          <check-box-multi
              :elements="stavList"
              :selected="localMultiFilters.stav"
              :filterName="FILTER_NAMES.STAV"
              :number="3"
              @check-box-multi-selected="selectMultiFilterValues"
          />
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.OBJECT)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.OBJECT)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Objekt obchodního případu</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.OBJECT)"
            />
          </div>
          <check-box-multi
              :elements="objectList"
              :selected="localMultiFilters.object"
              :filterName="FILTER_NAMES.OBJECT"
              :number="1"
              @check-box-multi-selected="selectMultiFilterValues"
          />
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.OKRES_OP)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.OKRES_OP)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Okres obchodního případu</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.OKRES_OP)"
            />
          </div>
          <multiselect
              v-model="localMultiFilters.okresOP"
              placeholder="Vybrat okresy"
              :options="okresyList"
              :multiple="true"
              :hideSelected="true"
              :max-height="130"
              selectLabel="Pro výběr stiskněte enter"
          />
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.KRAJ)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.KRAJ)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Kraj obchodního případu</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.KRAJ)"
            />
          </div>
          <check-box-multi
              :elements="krajList"
              :selected="localMultiFilters.kraj"
              :filterName="FILTER_NAMES.KRAJ"
              :number="3"
              @check-box-multi-selected="selectMultiFilterValues"
          />
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.PSC)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.PSC)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Poštovní směrovací číslo (PSČ)</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.PSC, true)"
            />
          </div>
          <div class="d-flex pb-1">
            <b-form-input
                id="zip"
                type="text"
                size="sm"
                v-model.trim="localMultiFilters.zip"
                v-mask="'### ##'"
                class="d-inline-block w-150px text-black"
            />
          </div>
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.AKTIVNI_OZ)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.AKTIVNI_OZ)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Obchodní zástupci (aktivní)</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.AKTIVNI_OZ)"
            />
          </div>
          <multiselect
              v-model="localMultiFilters.aktivniOZ"
              placeholder="Vybrat skupinu lidí"
              label="name"
              track-by="id"
              :options="obchodniZastupceList"
              :multiple="true"
              :hideSelected="true"
              :max-height="130"
              selectLabel="Pro výběr stiskněte enter"
          />
        </b-card>

        <b-card v-if="canSeeObchodniZastupce && isShowFilter(FILTER_NAMES.DEAKTIVOVANE_OZ)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.DEAKTIVOVANE_OZ)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Obchodní zástupci (deaktivované)</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.DEAKTIVOVANE_OZ)"
            />
          </div>
          <multiselect
              v-model="localMultiFilters.deaktivovaneOZ"
              placeholder="Vybrat skupinu lidí"
              label="name"
              track-by="id"
              :options="obchodniZastupceDeactivatedList"
              :multiple="true"
              :hideSelected="true"
              :max-height="130"
              selectLabel="Pro výběr stiskněte enter"
          />
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.MANAGER_KOORDINATOR)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.MANAGER_KOORDINATOR)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Projektový manažer / koordinátor</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeMultiFilter(FILTER_NAMES.MANAGER_KOORDINATOR)"
            />
          </div>
          <multiselect
              v-model="localMultiFilters.managerKoordinator"
              placeholder="Vybrat skupinu lidí"
              label="name"
              track-by="id"
              :options="managerKoordinatorList"
              :multiple="true"
              :hideSelected="true"
              :max-height="130"
              selectLabel="Pro výběr stiskněte enter"
          />
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.DATUM_ZALOZENI_PRIPADU)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.DATUM_ZALOZENI_PRIPADU)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Datum založení případu</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeDateMultiFilter(FILTER_NAMES.DATUM_ZALOZENI_PRIPADU)"
            />
          </div>
          <div class="d-flex pb-1">
            <span class="ml-3 lh-2 pt-2px">od</span>
            <b-form-datepicker
                id="dataFromDatumZalozeni-datepicker"
                v-model="localMultiFilters.datumZalozeniPripadu.dateFrom"
                :start-weekday="1"
                size="sm"
                locale="cs"
                class="ml-2 w-260px"
            />
            <span class="ml-3 lh-2 pt-2px">do</span>
            <b-form-datepicker
                id="dataToDatumZalozeni-datepicker"
                v-model="localMultiFilters.datumZalozeniPripadu.dateTo"
                :start-weekday="1"
                size="sm"
                locale="cs"
                class="ml-2 w-260px"
            />
          </div>
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.DATUM_POSLEDNI_AKTIVITY)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.DATUM_POSLEDNI_AKTIVITY)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Datum poslední aktivity</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeDateMultiFilter(FILTER_NAMES.DATUM_POSLEDNI_AKTIVITY)"
            />
          </div>
          <div class="d-flex pb-1">
            <span class="ml-3 lh-2 pt-2px">od</span>
            <b-form-datepicker
                id="dataFromDatumAktivity-datepicker"
                v-model="localMultiFilters.datumPosledniAktivity.dateFrom"
                :start-weekday="1"
                size="sm"
                locale="cs"
                class="ml-2 w-260px"
            />
            <span class="ml-3 lh-2 pt-2px">do</span>
            <b-form-datepicker
                id="dataToDatumAktivity-datepicker"
                v-model="localMultiFilters.datumPosledniAktivity.dateTo"
                :start-weekday="1"
                size="sm"
                locale="cs"
                class="ml-2 w-260px"
            />
          </div>
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.DATUM_NAPLANOVANE_AKTIVITY)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.DATUM_NAPLANOVANE_AKTIVITY)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Datum naplánované aktivity</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeDateMultiFilter(FILTER_NAMES.DATUM_NAPLANOVANE_AKTIVITY)"
            />
          </div>
          <div class="d-flex pb-1">
            <span class="ml-3 lh-2 pt-2px">od</span>
            <b-form-datepicker
                id="dataFromDatumNaplanovaneAktivity-datepicker"
                v-model="localMultiFilters.datumNaplanavaneAktivity.dateFrom"
                :start-weekday="1"
                size="sm"
                locale="cs"
                class="ml-2 w-260px"
            />
            <span class="ml-3 lh-2 pt-2px">do</span>
            <b-form-datepicker
                id="dataToDatumNaplanovaneAktivity-datepicker"
                v-model="localMultiFilters.datumNaplanavaneAktivity.dateTo"
                :start-weekday="1"
                size="sm"
                locale="cs"
                class="ml-2 w-260px"
            />
          </div>
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.NAPOSLEDY_ZMENENO)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.NAPOSLEDY_ZMENENO)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Datum poslední změny stavu</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeDateMultiFilter(FILTER_NAMES.NAPOSLEDY_ZMENENO)"
            />
          </div>
          <div class="d-flex pb-1">
            <span class="ml-3 lh-2 pt-2px">od</span>
            <b-form-datepicker
                id="dataFromDatumZmeneno-datepicker"
                v-model="localMultiFilters.naposledyZmeneno.dateFrom"
                :start-weekday="1"
                size="sm"
                locale="cs"
                class="ml-2 w-260px"
            />
            <span class="ml-3 lh-2 pt-2px">do</span>
            <b-form-datepicker
                id="dataToDatumZmeneno-datepicker"
                v-model="localMultiFilters.naposledyZmeneno.dateTo"
                :start-weekday="1"
                size="sm"
                locale="cs"
                class="ml-2 w-260px"
            />
          </div>
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.HODNOTA_OP)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.HODNOTA_OP)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Hodnota obchodního případu</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeImputsFilter(FILTER_NAMES.HODNOTA_OP)"
            />
          </div>
          <div class="d-flex pb-1">
            <span class="ml-3 lh-2 pt-2px">od</span>
            <b-form-input
                id="searchString"
                type="text"
                size="sm"
                v-model.trim="localMultiFilters.hodnotaOP.from"
                class="d-inline-block w-250px ml-3 text-black"
            />
            <span class="ml-3 lh-2 pt-2px">do</span>
            <b-form-input
                id="searchString"
                type="text"
                size="sm"
                v-model.trim="localMultiFilters.hodnotaOP.to"
                class="d-inline-block w-250px ml-3 text-black"
            />
          </div>
        </b-card>

        <b-card v-if="isShowFilter(FILTER_NAMES.PRAVDEPODOBNOST)"
                class="w-100 mf-card mt-3" :class="getOrderClass(FILTER_NAMES.PRAVDEPODOBNOST)"
        >
          <div class="mb-2">
            <span class="multifilter-title">Pravděpodobnost výhry</span>
            <b-icon-x-circle
                class="pl-2 pt-1 cursor-pointer va--0_25 filtered"
                font-scale="1.8"
                @click="removeImputsFilter(FILTER_NAMES.PRAVDEPODOBNOST)"
            />
          </div>
          <div class="d-flex pb-1">
            <span class="ml-3 lh-2 pt-2px">od</span>
            <b-form-select
                name="searchStringPravdepodobnostDo"
                v-model="localMultiFilters.pravdepodobnost.from"
                :options="lists.pravdepodobnost"
                class="form-select form-select-sm w-80px ml-2"
                size="sm"
            />
            <span class="ml-4 lh-2 pt-2px">do</span>
            <b-form-select
                name="searchStringPravdepodobnostTo"
                v-model="localMultiFilters.pravdepodobnost.to"
                :options="lists.pravdepodobnost"
                class="form-select form-select-sm w-80px ml-2"
                size="sm"
            />
          </div>
        </b-card>

      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import CheckBoxMulti from "@/views/CheckBoxMultiSubComponent.vue";
import {PRAVDEPODOBNOST_LIST} from "@/constants/form.constants";
import _ from "lodash";

const FILTER_NAMES = {
  STAV: 'stav',
  OKRES_OP: 'okresOP',
  KRAJ: 'kraj',
  OBJECT: 'object',
  AKTIVNI_OZ: 'aktivniOZ',
  DEAKTIVOVANE_OZ: 'deaktivovaneOZ',
  MANAGER_KOORDINATOR: 'managerKoordinator',
  DATUM_ZALOZENI_PRIPADU: 'datumZalozeniPripadu',
  DATUM_POSLEDNI_AKTIVITY: 'datumPosledniAktivity',
  DATUM_NAPLANOVANE_AKTIVITY: 'datumNaplanavaneAktivity',
  NAPOSLEDY_ZMENENO: 'naposledyZmeneno',
  HODNOTA_OP: 'hodnotaOP',
  PSC: 'zip',
  NAZEV_V_ADRESARE: 'nazevVAdresari',
  PRAVDEPODOBNOST: 'pravdepodobnost',
}

const multiFiltersList = [
  {value: FILTER_NAMES.NAZEV_V_ADRESARE, text: "Název adresáře v zakázkách"},
  {value: FILTER_NAMES.OBJECT, text: "Objekt obchodního případu"},
  {value: FILTER_NAMES.STAV, text: "Stav obchodního případu"},
  {value: FILTER_NAMES.OKRES_OP, text: "Okres obchodního případu"},
  {value: FILTER_NAMES.KRAJ, text: "Kraj obchodního případu"},
  {value: FILTER_NAMES.PSC, text: "Poštovní směrovací číslo (PSČ)"},
  {value: "", text: "---", disabled: true},
  {value: FILTER_NAMES.AKTIVNI_OZ, text: "Obchodní zástupci (aktivní)"},
  {value: FILTER_NAMES.DEAKTIVOVANE_OZ, text: "Obchodní zástupci (deaktivované)"},
  {value: FILTER_NAMES.MANAGER_KOORDINATOR, text: "Projektový manažer / koordinátor"},
  {value: "", text: "---", disabled: true},
  {value: FILTER_NAMES.DATUM_ZALOZENI_PRIPADU, text: "Datum založení případu"},
  {value: FILTER_NAMES.DATUM_POSLEDNI_AKTIVITY, text: "Datum poslední aktivity"},
  {value: FILTER_NAMES.DATUM_NAPLANOVANE_AKTIVITY, text: "Datum naplánované aktivity"},
  {value: FILTER_NAMES.NAPOSLEDY_ZMENENO, text: "Datum poslední změny stavu"},
  {value: "", text: "---", disabled: true},
  {value: FILTER_NAMES.HODNOTA_OP, text: "Hodnota obchodního případu"},
  {value: FILTER_NAMES.PRAVDEPODOBNOST, text: "Pravděpodobnost výhry"},
]

export default {
  name: 'stavMultifiltr',
  components: {CheckBoxMulti},
  props: {
    stavList: [],
    okresyList: [],
    krajList: [],
    objectList: [],
    obchodniZastupceList: [],
    obchodniZastupceDeactivatedList: [],
    managerKoordinatorList: [],
    propsMultiFilters: {},
  },
  data() {
    return {
      multifilters: this.clearedFiltersList(),
      multiFilterForSelect: null,
      selectedMultiFilters: [],
      showLocal: false,
      isLoading: false,
      FILTER_NAMES,
      localMultiFilters: _.cloneDeep(this.propsMultiFilters),
      lists: {
        pravdepodobnost: PRAVDEPODOBNOST_LIST,
      },
    }
  },
  computed: {
    filteredMultiFilters() {
      const clearedFiltersList = this.clearedFiltersList();
      return clearedFiltersList.filter(multiFilter => {
        return !this.selectedMultiFilters.includes(multiFilter.value);
      })
    }
  },
  methods: {
    clearedFiltersList() {
      if (this.canSeeObchodniZastupce()) return multiFiltersList;

      return multiFiltersList.filter(multiFilter => {
        return ![FILTER_NAMES.AKTIVNI_OZ, FILTER_NAMES.DEAKTIVOVANE_OZ].includes(multiFilter.value);
      })
    },
    addFilter() {
      if (!this.multiFilterForSelect) return;

      this.selectedMultiFilters.push(this.multiFilterForSelect);
      this.multiFilterForSelect = null;
    },
    acceptMultiFilter() {
      this.$emit('accept-multi-filter', this.localMultiFilters);
    },
    removeMultiFilter(filterName, isString = false) {
      this.selectedMultiFilters = this.selectedMultiFilters.filter(item => item !== filterName);
      this.localMultiFilters[filterName] = isString ? '' : [];
    },
    removeDateMultiFilter(filterName) {
      this.selectedMultiFilters = this.selectedMultiFilters.filter(item => item !== filterName);
      this.localMultiFilters[filterName] = {dateFrom: null, dateTo: null};
    },
    removeImputsFilter(filterName) {
      this.selectedMultiFilters = this.selectedMultiFilters.filter(item => item !== filterName);
      this.localMultiFilters[filterName] = {from: null, to: null};
    },

    selectMultiFilterValues(filterName, newVal) {
      _.set(this, `localMultiFilters.${filterName}`, newVal);
    },
    isShowFilter(filterName) {
      return this.selectedMultiFilters?.includes(filterName);
    },
    getSelected(){
      const mfWithDates = [FILTER_NAMES.DATUM_ZALOZENI_PRIPADU, FILTER_NAMES.DATUM_POSLEDNI_AKTIVITY, FILTER_NAMES.DATUM_NAPLANOVANE_AKTIVITY, FILTER_NAMES.NAPOSLEDY_ZMENENO];
      const mfWithObj = [FILTER_NAMES.HODNOTA_OP, FILTER_NAMES.PRAVDEPODOBNOST];
      const mfWithImputs = [FILTER_NAMES.PSC, FILTER_NAMES.NAZEV_V_ADRESARE];

      for (let key in this.propsMultiFilters) {
        if (Array.isArray(this.propsMultiFilters[key]) && this.propsMultiFilters[key].length) {
          this.selectedMultiFilters.push(key)
        } else if (mfWithDates.includes(key)) {
          if (this.checkDateObjOnEmpty(this.propsMultiFilters[key])) {
            this.selectedMultiFilters.push(key)
          }
        } else if (mfWithObj.includes(key)) {
          if (this.checkFromToObjEmpty(this.propsMultiFilters[key])) {
            this.selectedMultiFilters.push(key)
          }
        } else if (mfWithImputs.includes(key)) {
          if (this.propsMultiFilters[key]) {
            this.selectedMultiFilters.push(key)
          }
        }
      }
    },
    checkDateObjOnEmpty(dateObj) {
      let {dateFrom, dateTo} = dateObj;
      return !!dateFrom || !!dateTo;
    },
    checkFromToObjEmpty(obj) {
      let {from, to} = obj;
      return !!from || !!to;
    },
    canSeeObchodniZastupce() {
      return !this.$store.getters.isCanSeeOnlyForOwn;
    },
    getOrderClass(filterName) {
      const index = this.selectedMultiFilters.indexOf(filterName);
      if (index === -1) return '';

      return `order-${this.selectedMultiFilters.length - index}`;
    },
  },
  mounted() {
    this.isLoading = true;
    this.showLocal = true;
    this.getSelected();
    this.isLoading = false;
  }
}
</script>
<style scoped>
.multifilter-title {
  font-size: 14px;
  font-weight: 700;
  color: #0077cc;
}
.mf-card .card-body {
  padding: 0.25rem 0.75rem;
}
</style>