<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="vlozitSouboryFoto-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Soubory a fota obhlídky k zakázce č. {{ kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>

      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import commonMixin from "@/mixins/common.mixin.ts";
import axios from "axios";

export default {
  props: {
    id: {type: String},
    show: {type: Boolean},
    kodZakazky: {type: String},
  },
  mixins: [commonMixin],
  computed: {
    isHaveAccess() {
      return this.$store.getters.isObhlidkovyFormularModalAllowed;
    },
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      dataForm: {
        // kodZakazky: null,
      },
    }
  },
  methods: {
    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
    async saveForm() {
      try {
        // const url = `/obchod/saveObhlidkovyFormular`;
        // const {data} = await axios.post(url, { data: this.contract });
        // this.$snotify[data.status](data.data.message);
        // if (data.status === 'success') {
        //   this.$emit('form-saved')
        //   if (!isNotClose) this.$emit('close-modal');
        // }
      } catch (e) {
        console.error('saveInspectionForm', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getSavedData() {
      try {
        const url = `/obchod/getVlozitSouboryFotoFormInfo`;
        const {data: {data}} = await axios.get(url, {params: {id: this.id}});
        const info = data?.info || {};
        this.dataForm = {...this.dataForm, ...info};
      } catch (e) {
        console.error('VlozitSouboryFotoForm getSavedData', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    // await this.getConstants();
   // await this.getSavedData();
    this.isLoading = false;
    this.showLocal = this.show;
  },

}
</script>

<style scoped>

</style>