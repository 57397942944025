<template>
  <div v-if="$store.getters.isObchodPageAllowed" class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="text-left button-line pl-1">
        <b-btn
            v-if="$store.getters.isObchodniPripadModalAllowed"
            @click="openZakazkaModal()"
            variant="outline-primary"
        >
          Nový obchodní případ
        </b-btn>
      </div>

      <template>
        <div class="d-flex justify-content-between">
          <div class="text-left filter-line d-flex">
            <span class="filtry-text">Filtry:</span>
            <b-form-select
                name="filterTypes"
                v-model="filterType"
                :options="listFilterTypes"
                class="form-select form-select-sm d-inline-block w-230px text-black"
                size="sm"
                @change="changeFilterTypes"
            />

            <template v-if="isFilterTypeNazev || isFilterZip || isFilterPhone">
              <b-form-input
                  id="searchString"
                  type="text"
                  size="sm"
                  v-model.trim="filter.searchString"
                  class="d-inline-block w-250px ml-3 text-black"
                  :class="[filter.searchString ? 'input-filtered' : '']"
                  placeholder="napište co hledáte..."
                  v-debounce="searchByString"
              />
            </template>
            <template v-else-if="isFilterTypeObject">
              <b-form-select
                  name="object"
                  v-model="objectOption"
                  :options="lists.object"
                  class="d-inline-block w-170px ml-3 form-select form-select-sm"
                  size="sm"
                  @change="changeObjectSelect"
              >
                <template #first>
                  <option value=""></option>
                </template>
              </b-form-select>
            </template>
            <template v-else-if="isFilterTypeOzUsers">
              <b-form-input
                  id="obchodniZastupce"
                  list="obchodniZastupceList"
                  class="form-control form-control-sm w-250px ml-3 text-black"
                  v-model="filter.obchodniZastupceName"
                  @input="changeOzUsers"
              />
              <b-datalist
                  id="obchodniZastupceList"
                  :options="ozUsersNames"
                  autocomplete="off"
              />
            </template>
            <template v-else-if="isFilterTypeStav">
              <b-form-select
                  name="stav"
                  v-model="stavOption"
                  :options="lists.stav"
                  class="d-inline-block w-250px ml-3 form-select form-select-sm"
                  size="sm"
                  @change="changeStavSelect"
              >
                <template #first>
                  <option value=""></option>
                </template>
              </b-form-select>
            </template>
            <template v-else-if="isFilterTypeOkresOP">
              <b-form-input
                  list="okresOPList"
                  name="okresOP"
                  class="form-control form-control-sm w-250px ml-3 text-black"
                  v-model="filter.okresOP"
                  @change="changeOkresSelect"
              />
              <b-datalist
                  id="okresOPList"
                  :options="lists.okresy"
                  autocomplete="off"
              />
            </template>
            <template v-else-if="isFilterTypeKraj">
              <b-form-select
                  name="kraj"
                  v-model="krajOption"
                  :options="KRAJ_SHORT"
                  class="d-inline-block w-250px ml-3 form-select form-select-sm"
                  size="sm"
                  @change="changeKrajSelect"
              >
                <template #first>
                  <option value=""></option>
                </template>
              </b-form-select>
            </template>
            <template v-else-if="isPManagerKoordinator">
              <b-form-input
                  list="managerKoordinatorList"
                  name="managerKoordinator"
                  class="form-control form-control-sm w-250px ml-3 text-black"
                  v-model="filter.managerKoordinatorName"
                  @change="changeManagerKoordinator"
              />
              <b-datalist
                  id="managerKoordinatorList"
                  :options="managerKoordinatorFioTitles"
                  autocomplete="off"
              />
            </template>

            <b-button
                v-if="showMultifiltr"
                :variant="getShowMultifilterButtonVariant()"
                size="sm"
                class="d-flex ml-3 bold h-29px"
                @click="openMultifiltrModal"
            ><b-icon-funnel
                class="cursor-pointer pt-3px"
                font-scale="1"
            />
              <span class="pl-1 pr-3px">Nastavit filtrovaní</span>
            </b-button>

            <b-icon-x-circle
                @click="clearFilter"
                class="pl-2 pt-1 cursor-pointer"
                :class="[isFiltered() ? 'filtered' : '']"
                font-scale="1.8"
                title="Čištění filtru"
            />
            <span class="pl-5 pt-3px">
              [ obchodních případů: {{ totalItems }} ]
            </span>
          </div>

          <div v-if="isShowPredaniOpMeziOzBlock" class="d-flex pr-3">
            <div v-if="showPredaniOpMeziOzBlock" class="danger-predat">
              <span class="">Předat {{ totalItems }} obchodních případů - </span>
              <b-form-select
                  name="opToOz"
                  v-model="ozPredani"
                  :options="getOzPredaniList"
                  class="d-inline-block w-150px ml-1 form-select form-select-sm mr-2"
                  size="sm"
              >
                <template #first>
                  <option value=""></option>
                </template>
              </b-form-select>
              <b-button v-b-modal.predani-op-modal variant="danger" size="sm" class="h-29px" :disabled="!ozPredani">
                Předat
              </b-button>
            </div>
            <b-button v-else
                      variant="outline-secondary"
                      size="sm"
                      class="h-29px"
                      @click="showPredaniOpMeziOzBlock=!showPredaniOpMeziOzBlock"
            >
              Předání OP jinému OZ
            </b-button>
            <b-icon-x-circle
                v-if="showPredaniOpMeziOzBlock"
                @click="showPredaniOpMeziOzBlock=false;ozPredani=null"
                class="pl-2 pt-2 cursor-pointer filtered"
                font-scale="1.8"
            />
          </div>
        </div>

        <b-table
            sticky-header="calc(100vh - 14.5rem)"
            bordered
            responsive
            hover
            no-border-collapse
            class="stickyTable"
            :items="contracts"
            :fields="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-direction="desc"
            no-local-sorting
            @sort-changed="changeOrder"
        >
          <template #cell(orderNumber)="{ item }" class="color1 bg-color-white">
            <div class="d-flex justify-content-between text-left">
              <template v-if="$store.getters.isObchodniPripadModalAllowed">
                <div>
                  <b-icon-clipboard-plus
                      class="user-enabled-email cursor-pointer"
                      v-b-tooltip.hover="{
                        title: 'Kopírovat název',
                        delay: { show: 200, hide: 200 },
                        variant: 'secondary'
                      }"
                      @click="copyOP(item)"
                  />
                  <a
                      href="#"
                      class="pl-2"
                      @click.prevent="openZakazkaModal(item)"
                      title="Upravit obchodní případ"
                  >
                    {{ item.orderNumber }}
                  </a>
                </div>
                <a
                    :href="`mailto:${item.personaEmail || ''}?subject=${item.orderNumber}`"
                    title="Poslat email zákazníkovi"
                    class="pl-2"
                >
                  <b-icon-envelope-fill class="user-enabled-email"/>
                </a>
              </template>
              <span v-else>
                {{ item.orderNumber }}
              </span>
            </div>
          </template>

          <template #cell(obchodniZastupce)="{ item }" class="color1 bg-color-white">
            <div v-html="getObchodniZastupceAndManagerKoordinatorName(item, managerKoordinatorObjById)">
            </div>
          </template>

          <template #cell(adresaInstalace)="{ item }" class="color1">
            <div class="text-left">
              <a
                  v-if="$store.getters.isObhlidkovyFormularModalAllowed"
                  :class="{ 'text-black': isObhlidkovyFormularFormSaved(item) }" href="#"
                  @click.prevent="showObhlidkovyFormularFormModal(item)"
                  :title="isObhlidkovyFormularFormSaved(item) ? 'Upravit «Obhlídkový formulář»' : 'Vyplnit «Obhlídkový formulář»'"
              >
                <template v-if="getShortAddress(item).length">
                  {{ getShortAddress(item) }}
                </template>
                <template v-else>
                  <b-icon-pencil-square/>
                </template>
              </a>
              <span v-else>
                <template v-if="getShortAddress(item).length">
                  {{ getShortAddress(item) }}
                </template>
                <template v-else>
                  <b-icon-pencil-square/>
                </template>
              </span>
            </div>
            <div class="text-left">
              <span class="fs-13px">{{ getKrajNameAndOkres(item) }}</span>
            </div>
          </template>

          <template #cell(stav)="{ item }" class="color1">
            <div class="text-center">
              <a v-if="$store.getters.isStavObchodnihoPripaduAllowed"
                 @click.prevent="showStavObchodnihoPripaduModal(item)"
                 title="Otevřít 'Stav obchodního případu' formulář"
                 href="#"
              >
                  <span v-if="isStavSelected(item)">
                    {{ getStaveAndDate(item.obchod) }}
                  </span>
                <b-icon-pencil-square v-else/>
              </a>
              <span v-else>
                  {{ getStaveAndDate(item.obchod) }}
                </span>
            </div>
          </template>

          <template #cell(poznamky)="{ item }" class="color1">
            <div class="text-center">
              <a v-if="$store.getters.isPoznamkyKobchodnimuPripaduAllowed"
                 @click.prevent="showPoznamkyKobchodnimuPripaduModal(item)"
                 title="Otevřít 'Poznámky k obchodnímu případu' formulář"
                 href="#"
              >
                      <span v-if="isShowPoznamkySaveDate(item)">
                        {{ getFormattedDate(item.obchod.poznamkySaveDate) }}
                      </span>
                <b-icon-pencil-square v-else/>
              </a>
              <span v-else>
                    {{ getFormattedDate(item.obchod.poznamkySaveDate) }}
                </span>
            </div>
          </template>

          <template #cell(GDPR)="{ item }" class="color1">
            <div class="text-center">
              <a v-if="$store.getters.isGDPRRodinnePrislusniciAllowed"
                 @click.prevent="showGDPRRodinnePrislusniciModal(item)"
                 title="Otevřít 'Rodinné příslušníci a GDPR' formulář"
                 href="#"
              >
                    <span v-if="isGDPRFilled(item) && item.obchod.GDPRSaveDate">
                      {{ getFormattedDate(item.obchod.GDPRSaveDate) }}
                    </span>
                <b-icon-pencil-square v-else/>
              </a>
              <span v-else>
                  <span v-if="isGDPRFilled(item)">
                    {{ getFormattedDate(item.obchod.GDPRSaveDate) }}
                  </span>
                </span>
            </div>
          </template>

          <template #cell(smlouvaDilo)="{ item }" class="color1">
            <div class="text-center">
              <a v-if="$store.getters.isSmlouvaFakturaAllowed && isGDPRFilled(item)"
                 @click.prevent="showSmlouvaDiloModal(item)"
                 v-b-tooltip.hover="{
                      title: `Otevřít 'Smlouva / faktury k zakázce' formulář`,
                      delay: { show: 200, hide: 200 },
                      variant: 'secondary'
                    }"
                 href="#"
              >
                <template v-if="item.podepsanaDate">
                  <span>
                    {{ getVerzeSoD(item.obchod) }}
                  </span>
                  <br>
                  <span>
                    ({{ getFormattedDate(item.podepsanaDate) }})
                  </span>
                </template>
                <b-icon-pencil-square v-else />
              </a>
              <template v-else>
                <template v-if="item.podepsanaDate">
                  <span>
                    {{ getVerzeSoD(item.obchod) }}
                  </span>
                  <br>
                  <span>
                    ({{ getFormattedDate(item.podepsanaDate) }})
                  </span>
                </template>
                <span v-else>
                  -
                </span>
              </template>
            </div>
          </template>

          <template #cell(dodatky)="{ item }" class="color1">
            <div class="text-center">
              <template v-if="$store.getters.isDodatkySmlouveAllowed">
                <span v-if="!item.podepsanaDate || checkIsFactura(item.obchod)">
                  -
                </span>
                <a v-else
                   @click.prevent="showDodatkeSmlouveModal(item)"
                   href="#"
                   v-b-tooltip.hover="{
                      title: `Otevřít 'Dodatky ke smlouvě' formulář`,
                      delay: { show: 200, hide: 200 },
                      variant: 'secondary'
                    }"
                >
                  <b-icon-pencil-square />
                </a>
              </template>
              <template v-else>
                <span>-</span>
              </template>
            </div>
          </template>

          <template #cell(zaevidovanoDate)="{ item }">
            <div class="text-center">
              <b-icon-journal
                  font-scale="1.3"
                  variant="secondary"
                  class="cursor-pointer"
                  @click="showKartaModal(item)"
              />
              <div class="fs-11px pt-1">
                {{ item.zaevidovanoDate ? `${getFormattedDate(item.zaevidovanoDate)}` : ''}}
              </div>
            </div>
          </template>

          <template #cell(pocetDniPoPosledniAktivite)="{ item }">
            <span v-if="!item.posledniAktivitaDate" class="bold text-red">
              není
            </span>
            <span v-else>
              {{ getPocetDniPoPosledniAktivite(item) }}
            </span>
          </template>

          <template #cell(pocetDniDoNaplanovaneAktivity)="{ item }">
            <div v-if="!item.naplanovanaAktivitaDate" class="bold text-red">
              není
            </div>
            <div v-else v-html="getPocetDniDoNaplanovaneAktivity(item)"></div>
          </template>

          <template #cell(predpokladanaCena)="{ item }">
            {{ formatMoneyZero(getHodnotaOP(item)) }}
          </template>

          <template #head()="{ field }">
            <span v-html="field.label"></span>
          </template>
        </b-table>

        <div v-if="!contracts || !contracts.length" class="pt-5">
          <h5 class="p-5 fs-23px">Žádné výsledky vyhledávání</h5>
        </div>
      </template>

      <obchodni-pripad-form
          v-if="showObchodniPripadModal && $store.getters.isObchodniPripadModalAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="getKodZakazkySelectedContract"
          :show="showObchodniPripadModal"
          :isNewObchodPripadFormCanBeSaved="isNewObchodPripadFormCanBeSaved"
          @close-modal="showObchodniPripadModal = false"
          @form-saved="formSaved"
          @show-existujici-obchodny-pripady-modal="showExistujiciObchodniPripadModal"
      />

      <obhlidkovy-formular-form
          v-if="showObhlidkovyFormularForm && $store.getters.isObhlidkovyFormularModalAllowed"
          :id="getIdSelectedContract"
          :show="showObhlidkovyFormularForm"
          :first-name="selectedContract.clientName"
          :last-name="selectedContract.clientLastName"
          @close-modal="showObhlidkovyFormularForm = false"
          @show-vlozit-soubory-foto-modal="showVlozitSouboryFotoModal"
          @form-saved="formSaved"
      />

      <stav-obchodniho-pripadu-form
          v-if="showStavObchodnihoPripaduForm && $store.getters.isStavObchodnihoPripaduAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showStavObchodnihoPripaduForm"
          @close-modal="showStavObchodnihoPripaduForm = false"
          @form-saved="formSaved"
      />

      <poznamky-k-obchodnimu-pripadu-form
          v-if="showPoznamkyKobchodnimuPripaduForm && $store.getters.isPoznamkyKobchodnimuPripaduAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showPoznamkyKobchodnimuPripaduForm"
          @close-modal="showPoznamkyKobchodnimuPripaduForm = false"
          @form-saved="formSaved"
      />

      <gpdr-form
          v-if="showGDPRRodinnePrislusniciForm && $store.getters.isGDPRRodinnePrislusniciAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showGDPRRodinnePrislusniciForm"
          @close-modal="showGDPRRodinnePrislusniciForm = false"
          @form-saved="formSaved"
      />

      <smlouva-faktura-form
          v-if="showSmlouvaFakturaForm && $store.getters.isSmlouvaFakturaAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showSmlouvaFakturaForm"
          @close-modal="showSmlouvaFakturaForm = false"
          @form-saved="formSaved"
      />

      <existujici-obchodni-pripad-form
          v-if="showExistujiciObchodniPripadForm && $store.getters.isObchodniPripadModalAllowed"
          :show="showExistujiciObchodniPripadForm"
          :newObchodPripadData="newObchodPripadData"
          @close-modal="showExistujiciObchodniPripadForm = false"
          @can-be-saved="newObchodPripadFormCanBeSaved"
      />

      <karta-form
          v-if="showKartaForm"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showKartaForm"
          @close-modal="showKartaForm = false"
      />

      <vlozit-soubory-foto-form
          v-if="showVlozitSouboryFotoForm && $store.getters.isObhlidkovyFormularModalAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showVlozitSouboryFotoForm"
          @close-modal="showVlozitSouboryFotoForm = false"
      />

      <stav-multifiltr
          v-if="showStavMultifilterForm"
          :stavList="lists.stav"
          :okresyList="lists.okresy"
          :krajList="KRAJ_SHORT"
          :objectList="lists.object"
          :obchodniZastupceList="transformOzUsers(activeOzUsersInfo)"
          :obchodniZastupceDeactivatedList="transformOzUsers(deactivatedOzUsersInfo)"
          :managerKoordinatorList="lists.managerKoordinator"
          :propsMultiFilters="filter.multiFilters"
          :show="showStavMultifilterForm"
          @close-modal="showStavMultifilterForm = false"
          @accept-multi-filter="acceptMultiFilter"
      />

      <dodatke-smlouve
          v-if="showDodatkeSmlouveForm && $store.getters.isSmlouvaFakturaAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showDodatkeSmlouveForm"
          @close-modal="showDodatkeSmlouveForm = false"
          @form-saved="formSaved"
      />

    </b-overlay>

    <b-modal id="predani-op-modal" centered hide-footer title="Předání OP mezi OZ" @hide="agreePredat=false" dialog-class="main-font">
      <p class="my-3">Chcete převést <span class="bold color-red"> {{ totalItems }}</span> OP, nalezených pomocí Multifiltru, uživateli <b>{{ getUserPredaniName }}</b>. Pokud jste si tím jistý(á), klikněte na tlačítko "<b>Předat</b>". V opačném případě klikněte na tlačítko "<b>Zrušit</b>" nebo okno zavřete.</p>
      <b-form-checkbox
          id="agree-1"
          v-model="agreePredat"
          name="checkbox-1"
          @change="changeAgreePredat"
          :unchecked-value="'false'"
      >
        Souhlasím
      </b-form-checkbox>
      <div class="d-flex justify-content-end pt-3">
        <b-button class="mr-3" variant="primary" @click="closePredaniModal">Zrušit</b-button>
        <b-button v-if="agreePredat" class="" variant="outline-danger" @click="predaniOpMeziOz">Předat</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ObchodniPripadForm from "@/components/modals/ObchodniPripadForm.vue";
import ObhlidkovyFormularForm from "@/components/modals/ObhlidkovyFormularForm.vue";
import StavObchodnihoPripaduForm from "@/components/modals/StavObchodnihoPripaduForm.vue";
import PoznamkyKObchodnimuPripaduForm from "@/components/modals/PoznamkyPripojit.vue";
import DodatkeSmlouve from "@/components/modals/DodatkeSmlouve.vue";
import GpdrForm from "@/components/modals/GDPRForm.vue";
import SmlouvaFakturaForm from "@/components/modals/SmlouvaFaktura.vue";
import commonMixin from "@/mixins/common.mixin.ts";
import axios from "axios";
import _ from "lodash";
import ExistujiciObchodniPripadForm from "@/components/modals/ExistujiciObchodniPripadyForm.vue";
import {
  FILTER_TYPES,
  LIST_OBCH_PRIPAD_FILTER_TYPES,
  PRIPAD_OBJECTS,
  VERZE_SOD,
  VERZE_SOD_TYPE,
  KRAJ,
  KRAJ_SHORT,
} from "@/constants/form.constants"
import KartaForm from "@/components/modals/KartaForm.vue";
import VlozitSouboryFotoForm from "@/components/modals/VlozitSouboryFotoForm.vue";
import moment from "moment";
import StavMultifiltr from "@/components/modals/StavMultifiltrForm.vue";

const defaultSortDirection = true;

export default {
  components: {
    ObhlidkovyFormularForm, ObchodniPripadForm, StavObchodnihoPripaduForm, PoznamkyKObchodnimuPripaduForm, GpdrForm,
    SmlouvaFakturaForm, ExistujiciObchodniPripadForm, KartaForm, VlozitSouboryFotoForm, StavMultifiltr, DodatkeSmlouve,
  },
  computed: {
    getIdSelectedContract() {
      return this.selectedContract?._id;
    },
    getKodZakazkySelectedContract() {
      return this.selectedContract?.kodZakazky;
    },
    isFilterTypeStav() {
      return this.filterType === FILTER_TYPES.STAV_OBCHODNIHO_PRIPADU;
    },
    isFilterTypeOkresOP() {
      return this.filterType === FILTER_TYPES.OKRES_OP;
    },
    isFilterTypeKraj() {
      return this.filterType === FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU;
    },
    isFilterTypeNazev() {
      return this.filterType === FILTER_TYPES.NAZEV_V_ADRESARE;
    },
    isFilterTypeOzUsers() {
      return [FILTER_TYPES.OZ_USERS_ACTIVE, FILTER_TYPES.OZ_USERS_DEACTIVATED].includes(this.filterType);
    },
    isFilterZip() {
      return this.filterType === FILTER_TYPES.ZIP;
    },
    isFilterPhone() {
      return this.filterType === FILTER_TYPES.PHONE;
    },
    isAddedMultifilterElements() {
      const checkArraysResult = this.checkArraysForValues(this.filter.multiFilters);
      const checkDatesResult = this.checkDatesForValues(this.filter.multiFilters);
      const checkStringsResult = this.checkStringsForValues(this.filter.multiFilters);
      return checkArraysResult || checkDatesResult || checkStringsResult;
    },
    isPManagerKoordinator() {
      return this.filterType === FILTER_TYPES.MANAGER_KOORDINATOR;
    },
    isFilterTypeObject() {
      return this.filterType === FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU;
    },
    isShowPredaniOpMeziOzBlock() {
      return !!this.isAddedMultifilterElements && this.$store.getters.isPredaniOpMeziOz;
    },
    getOzPredaniList() {
      return this.activeOzUsersInfo?.map(info => {
        return { value: info.id, text: info?.fullNameFioTitles }
      });
    },
    getUserPredaniName() {
      const user = this.getOzPredaniList?.find(elem => elem.value === this.ozPredani);
      return user?.text || '';
    },
  },
  mixins: [commonMixin],
  data() {
    return {
      KRAJ,
      KRAJ_SHORT,
      isLoading: false,
      contracts: [],
      showObchodniPripadModal: false,
      showObhlidkovyFormularForm: false,
      showStavObchodnihoPripaduForm: false,
      showPoznamkyKobchodnimuPripaduForm: false,
      showGDPRRodinnePrislusniciForm: false,
      showSmlouvaFakturaForm: false,
      showKartaForm: false,
      showExistujiciObchodniPripadForm: false,
      showVlozitSouboryFotoForm: false,
      showStavMultifilterForm: false,
      showDodatkeSmlouveForm: false,
      selectedContract: null,
      stavOption: '',
      krajOption: '',
      objectOption: '',
      sortBy: 'zaevidovanoDate',
      sortDesc: true,
      lists: {
        stav: [],
        object: [],
        managerKoordinator: [],
        okresy: [],
      },
      filter: {
        name: null,
        searchString: null,
        multiFilters: {
          stav: [],
          object: [],
          okresOP: [],
          kraj: [],
          aktivniOZ: [],
          deaktivovaneOZ: [],
          managerKoordinator: [],
          datumZalozeniPripadu: {dateFrom: null, dateTo: null},
          datumPosledniAktivity: {dateFrom: null, dateTo: null},
          datumNaplanavaneAktivity: {dateFrom: null, dateTo: null},
          naposledyZmeneno: {dateFrom: null, dateTo: null},
          hodnotaOP: {from: null, to: null},
          pravdepodobnost: {from: null, to: null},
          zip: null,
          nazevVAdresari: null,
        },
        obchodniZastupceName: null,
        managerKoordinatorName: null,
        managerKoordinatorId: null,
        okresOP: null,
        sortBy: null,
        sortDirection: defaultSortDirection,
      },
      headers: [
        {label: 'Karta případu<br><span class="font-weight-normal fs-11px">(datum založení)</span>', key: 'zaevidovanoDate', variant: 'color-1', class: 'pl-1 pr-1 mw-90px pt-2 pb-2', sortable: true},
        {label: 'Číslo obchodního případu<br><span class="font-weight-normal">(název adresáře v poptávkách / zakázkách)</span>', key: 'orderNumber', stickyColumn: true, variant: 'color-1', class: 'mw-400px stickyColumn', sortable: true},
        {label: 'Obchodní zástupce<br><span class="font-weight-normal">(projektový manažer / koordinátor)</span>', key: 'obchodniZastupce', variant: 'color-1', class: 'mw-160px', sortable: true},
        {label: 'Adresa instalace<br><span class="font-weight-normal">(obhlídkový formulář)</span>', key: 'adresaInstalace', variant: 'color-1', class: 'mw-350px max-w-350px', sortable: true},
        {label: 'Kontaktní údaje<br><span class="font-weight-normal">(GDPR, plné moci)</span>', key: 'GDPR', variant: 'color-1', class: 'mw-145px', sortable: true},
        {label: 'Poznámky<br><span class="font-weight-normal">(textový popis historie)</span>', key: 'poznamky', variant: 'color-1 mw-115px', sortable: true},
        {label: 'Počet dní po poslední aktivitě', key: 'pocetDniPoPosledniAktivite', variant: 'color-1', sortable: true},
        {label: 'Počet dní do naplánované aktivity', key: 'pocetDniDoNaplanovaneAktivity', variant: 'color-1', sortable: true},
        {label: 'Stav obchodního případu<br><span class="font-weight-normal">(plánovaní aktivit)</span>', key: 'stav', variant: 'color-1', class: 'mw-200px', sortable: true},
        {label: 'Hodnota obchodního případu,<br>bez DPH</span>', key: 'predpokladanaCena', variant: 'color-1', class: 'mw-125px', sortable: true},
        {label: 'Smlouvy a faktury<br><span class="font-weight-normal">(datum podpisu)</span>', key: 'smlouvaDilo', variant: 'color-1', class: 'mw-160px', sortable: true},
        {label: 'Dodatky ke smlouvě', key: 'dodatky', variant: 'color-1'},
      ],
      limit: 30,
      offset: 0,
      lastScrollTop: 0,
      newObchodPripadData: {},
      isNewObchodPripadFormCanBeSaved: false,
      listFilterTypes: this.getFilteredFilterTypes(),
      filterType: FILTER_TYPES.NAZEV_V_ADRESARE,
      ozUsers: [],
      ozUsersNames: [],
      activeOzUsersNames: [],
      activeOzUsersInfo: [],
      deactivatedOzUsersNames: [],
      deactivatedOzUsersInfo: {},
      curDateStart: moment().startOf('day'),
      managerKoordinatorId: null,
      managerKoordinator: [],
      managerKoordinatorFioTitles: [],
      managerKoordinatorFull: [],
      managerKoordinatorObjById: {},
      showMultifiltr: false,
      totalItems: 0,
      abortController: new AbortController(),
      abortControllerCount: new AbortController(),
      showPredaniOpMeziOzBlock: false,
      ozPredani: null,
      agreePredat: false,
    }
  },
  methods: {
    openZakazkaModal(item) {
      this.selectedContract = item;
      this.showObchodniPripadModal = true;
    },
    isFiltered() {
      return this.filter.searchString || this.filter.obchodniZastupceName || this.isAddedMultifilterElements || this.filter.managerKoordinatorName;
    },
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer(['STAV_STATUSES']) || {};
        this.lists.stav = dataConst.STAV_STATUSES;
        this.lists.object = PRIPAD_OBJECTS;
      } catch (e) {
        console.error('getConstants', e);
      }
    },
    async getObchodniPripadyCount(noCountQuery) {
      try {
        if (noCountQuery) return;
        const filterByUserId = this.getSelectedOzUserId();
        if (!filterByUserId && this.filter.obchodniZastupceName) return;

        this.abortControllerCount.abort();
        this.abortControllerCount = new AbortController();

        const url = `/obchod/getObchodniPripadyCount`;
        const {data: {data}} = await axios.post(url, {
          filter: this.filter,
          filterByUserId,
        }, {signal: this.abortControllerCount.signal});
        this.totalItems = data?.total || 0;
      } catch (e) {
        console.error('getObchodniPripadyCount', e);
        throw new Error()
      }
    },
    async getInfo(noCountQuery = false) {
      await Promise.all([
        this.getListObchodniPripady(noCountQuery),
        this.getObchodniPripadyCount(noCountQuery),
      ]);
    },
    async getListObchodniPripady(/*noCountQuery*/) {
      const filterByUserId = this.getSelectedOzUserId();
      if (!filterByUserId && this.filter.obchodniZastupceName) return;

      this.abortController.abort();
      this.abortController = new AbortController();

      try {
        this.isLoading = true; // !noCountQuery;
        const url = `/obchod/getListObchodniPripady`;
        this.filter.managerKoordinatorId = this.getSelectedManagerKoordinatorId();
        const {data: {data}} = await axios.post(url, {
              offset: this.offset,
              limit: this.limit,
              filter: this.filter,
              filterByUserId,
            },
            {signal: this.abortController.signal}
        );

        if (!this.offset) {
          this.contracts = data.data;
        } else {
          this.contracts.push(...data.data);
        }
        this.offset = this.offset > this.contracts.length ? this.offset : this.limit + this.offset;
        this.loading = false;
        this.isLoading = false;
      } catch (e) {
        if (!e?.response?.data?.message) {
          // console.error('Operation canceled');
        } else {
          console.error('getContracts', e.response?.data);
          console.error('get Contracts message', e.response?.data?.message);
          const errMsg = e.response?.data?.message;
          this.$snotify['error'](errMsg || 'Internal Server Error');
          this.loading = false;
          this.isLoading = false;
        }
      }
    },
    async getManagersKoordinators() {
      const url = `/user/getManagersKoordinators`;
      const {data} = await axios.get(url, {});
      this.managerKoordinator = data?.data?.managersKoordinatorsNames || [];
      this.managerKoordinatorFioTitles = data?.data?.managersKoordinatorsNamesFioTitles || [];
      this.managerKoordinatorFull = data?.data?.managersKoordinatorsInfo || [];
      this.managerKoordinatorFull.forEach(user => {
        this.managerKoordinatorObjById[user.id] = user.fullName;
        this.lists.managerKoordinator.push({name: user.fullName, id: user.id});
      })
    },
    async formSaved(type = null, kodZakazky = null) {
      this.offset = 0;
      if (type === 'add') {
        this.offset = 0;
        this.filter.name = FILTER_TYPES.NAZEV_V_ADRESARE;
        this.filter.searchString = kodZakazky;
        await this.$store.dispatch('getActivitiesCount');
      }
      await this.getInfo();
    },
    showObhlidkovyFormularFormModal(item) {
      this.selectedContract = item;
      this.showObhlidkovyFormularForm = true;
    },
    showStavObchodnihoPripaduModal(item) {
      this.selectedContract = item;
      this.showStavObchodnihoPripaduForm = true;
    },
    showPoznamkyKobchodnimuPripaduModal(item) {
      this.selectedContract = item;
      this.showPoznamkyKobchodnimuPripaduForm = true;
    },
    showGDPRRodinnePrislusniciModal(item) {
      this.selectedContract = item;
      this.showGDPRRodinnePrislusniciForm = true;
    },
    showSmlouvaDiloModal(item) {
      this.selectedContract = item;
      this.showSmlouvaFakturaForm = true;
    },
    showKartaModal(item) {
      this.selectedContract = item;
      this.showKartaForm = true;
    },
    showDodatkeSmlouveModal(item) {
      this.selectedContract = item;
      this.showDodatkeSmlouveForm = true;
    },
    getPocetDniPoPosledniAktivite(item) {
      const posledniAktivitaDate = moment(item.posledniAktivitaDate).startOf('day');
      return this.curDateStart.diff(posledniAktivitaDate, 'days');
    },
    getPocetDniDoNaplanovaneAktivity(item) {
      const naplanovanaAktivitaDate = moment(item.naplanovanaAktivitaDate).startOf('day');
      const dayCounts = naplanovanaAktivitaDate.diff(this.curDateStart, 'days');
      let stringInfo = '';
      if (item.activityDone) {
        stringInfo = `${dayCounts}<br>(splněno)`;
      } else if (dayCounts < 0) {
        stringInfo = `<span class="bold text-red">${dayCounts}</span>`;
      } else if (dayCounts > 0) {
        stringInfo = `<span class="bold dark-blue">${dayCounts}</span>`;
      } else {
        stringInfo = `${dayCounts}`;
      }
      return stringInfo;
    },
    getHodnotaOP(item) {
      return _.get(item, 'predpokladanaCena', 0);
    },
    showExistujiciObchodniPripadModal(formData) {
      this.showExistujiciObchodniPripadForm = true;
      this.newObchodPripadData = formData;
    },
    showVlozitSouboryFotoModal() {
      this.showVlozitSouboryFotoForm = true;
    },
    isObhlidkovyFormularFormSaved(item) {
      return !!item.clientPhone;
    },
    async handleScroll() {
      const tableEl = this.$el.querySelector('.table-responsive');
      const currentScrollTop = tableEl.scrollTop;
      if (currentScrollTop > this.lastScrollTop && (currentScrollTop + tableEl.offsetHeight + 5 >= tableEl.scrollHeight) && !this.loading) {
        this.loading = true;
        await this.getInfo(true);
      }
      this.lastScrollTop = currentScrollTop;
    },
    isShowPoznamkySaveDate(item) {
      return !!_.get(item, 'obchod.poznamkySaveDate', false);
    },
    isStavSelected(item) {
      return !!_.get(item, 'obchod.stav', false);
    },
    isGDPRFilled(item) {
      return !!(_.get(item, 'obchod.personasInfo', [])).length;
    },
    getStaveAndDate(elem) {
      const stavDateString = elem?.stavDate ? ` (${this.getFormattedDate(elem.stavDate)})` : '';
      const stavConstant = this.lists.stav.filter(c => c.value === elem.stav);
      const stavText = stavConstant?.length ? stavConstant[0].text : '';
      return elem?.stav ? `${stavText}${stavDateString}` : '';
    },
    getKrajNameAndOkres(item) {
      let result = [];
      result.push(this.getKrajName(item.kraj, this.KRAJ));
      result.push(this.getOkres(item));
      result = result.filter(r => r);
      return result.length ? `(${result.join(', ')})` : '';
    },
    async searchByString() {
      if (!this.isFilterTypeNazev && !this.isFilterZip && !this.isFilterPhone) return;

      this.offset = 0;
      this.filter.name = this.filterType;
      await this.getInfo();
    },
    async clearFilter() {
      const isFiltered = this.isFiltered();
      const isOzFilterType = [FILTER_TYPES.OZ_USERS_ACTIVE, FILTER_TYPES.OZ_USERS_DEACTIVATED].includes(this.filterType);
      this.offset = 0;
      this.filter.name = this.filterType;
      this.filter.searchString = null;
      this.filter.obchodniZastupceName = null;
      this.filter.managerKoordinatorName = null;
      this.filter.okresOP = null;
      this.filter.multiFilters = {
        stav: [], kraj: [], aktivniOZ: [], deaktivovaneOZ: [], managerKoordinator: [], datumZalozeniPripadu: {dateFrom: null, dateTo: null},
        datumPosledniAktivity: {dateFrom: null, dateTo: null}, datumNaplanavaneAktivity: {dateFrom: null, dateTo: null},
        naposledyZmeneno: {dateFrom: null, dateTo: null}, hodnotaOP: {from: null, to: null}, pravdepodobnost: {from: null, to: null},
        object: [], okresOP: [],
        zip: null, nazevVAdresari: null,
      };
      this.stavOption = null;
      this.krajOption = null;
      this.objectOption = null;
      this.resetScrollTop();
      if (isFiltered || isOzFilterType) await this.getInfo();
    },
    newObchodPripadFormCanBeSaved() {
      this.isNewObchodPripadFormCanBeSaved = true;
    },
    async changeSelectList(searchString, filterName) {
      this.offset = 0;
      this.filter.name = filterName;
      this.filter.searchString = searchString;
      this.resetScrollTop();
      await this.getInfo();
    },
    async changeStavSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.STAV_OBCHODNIHO_PRIPADU);
    },
    async changeKrajSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU);
    },
    async changeObjectSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU);
    },
    async changeOkresSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.OKRES_OP);
    },
    async changeFilterTypes() {
      switch (this.filterType) {
        case FILTER_TYPES.OZ_USERS_ACTIVE:
          this.ozUsers = this.activeOzUsersInfo;
          this.ozUsersNames = this.activeOzUsersNames;
          break;
        case FILTER_TYPES.OZ_USERS_DEACTIVATED:
          this.ozUsers = this.deactivatedOzUsersInfo;
          this.ozUsersNames = this.deactivatedOzUsersNames;
          break;
        case FILTER_TYPES.MULTIFILTR:
          this.openMultifiltrModal();
          break;
      }
      this.showMultifiltr = this.filterType === FILTER_TYPES.MULTIFILTR;
      await this.clearFilter();
    },
    openMultifiltrModal() {
      this.showStavMultifilterForm = true;
    },
    getFilteredFilterTypes() {
      return this.getFilteredByOzUsersFilterTypes(LIST_OBCH_PRIPAD_FILTER_TYPES)
    },
    async getOzUsersGroupsForUser() {
      try {
        const url = `/user/getOzUsersGroupsForUser`;
        const {data} = await axios.get(url, {});
        this.activeOzUsersNames = data?.data?.activeOzUsersNamesFioTitles || [];
        this.activeOzUsersInfo = data?.data?.activeOzUsersInfo || [];
        this.deactivatedOzUsersNames = data?.data?.deactivatedOzUsersNamesFioTitles || [];
        this.deactivatedOzUsersInfo = data?.data?.deactivatedOzUsersInfo || [];
      } catch (error) {
        console.log(error);
      }
    },
    async getOkresy() {
      try {
        this.lists.okresy = await this.getOkresyList();
      } catch (error) {
        console.log(error);
      }
    },
    getSelectedOzUserId() {
      return _.first(this.ozUsers.filter(u => u.fullNameFioTitles === this.filter.obchodniZastupceName))?.id;
    },
    getSelectedManagerKoordinatorId() {
      return _.first(this.managerKoordinatorFull.filter(u => u.fullNameFioTitles === this.filter.managerKoordinatorName))?.id;
    },
    changeOzUsers() {
      this.offset = 0;
      this.resetScrollTop();
      this.getInfo();
    },
    changeManagerKoordinator() {
      this.offset = 0;
      this.resetScrollTop();
      this.getInfo();
    },
    acceptMultiFilter(multiFilters) {
      this.filter.multiFilters = multiFilters;
      this.offset = 0;
      this.filter.name = FILTER_TYPES.MULTIFILTR;
      this.resetScrollTop();
      this.getInfo();
      this.showStavMultifilterForm = false;
    },
    getShowMultifilterButtonVariant() {
      return this.isAddedMultifilterElements ? 'info' : 'outline-info';
    },
    checkQueryParams() {
      if (this.$route.query?.kod) {
        this.filter.name = FILTER_TYPES.NAZEV_V_ADRESARE;
        this.filter.searchString = this.$route.query.kod;
      }
    },
    checkArraysForValues(obj) {
      for (let key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length > 0) {
          return true;
        }
      }
      return false;
    },
    checkStringsForValues(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'string' && obj[key].length > 0) {
          return true;
        }
      }
      return false;
    },
    checkDatesForValues(obj) {
      if (
          this.checkDateObjOnEmpty(obj.datumZalozeniPripadu) || this.checkDateObjOnEmpty(obj.datumPosledniAktivity)
          || this.checkDateObjOnEmpty(obj.datumNaplanavaneAktivity) || this.checkDateObjOnEmpty(obj.naposledyZmeneno)
          || this.checkFromToObjEmpty(obj.hodnotaOP) || this.checkFromToObjEmpty(obj.pravdepodobnost)
      ) {
        return true;
      }
    },
    checkDateObjOnEmpty(dateObj) {
      let {dateFrom, dateTo} = dateObj;
      return !!dateFrom || !!dateTo;
    },
    checkFromToObjEmpty(obj) {
      let {from, to} = obj;
      return !!from || !!to;
    },
    transformOzUsers(ozUsers) {
      return ozUsers.map(u => ({name: u.fullNameFioTitles, id: u.id}));
    },
    async predaniOpMeziOz() {
      try {
        this.agreePredat = false;
        this.$bvModal.hide('predani-op-modal');

        const url = `/obchod/predaniOpMeziOz`;
        const {data: {data, code}} = await axios.post(url, {filter: this.filter, transferTo: this.ozPredani});

        if (code === 200) {
          this.$snotify['success']('OP úspěšně přenesen');
          this.offset = 0;
          await this.getInfo();
        } else {
          this.$snotify['error'](data?.message || 'Chyba přenosu OP');
        }
      } catch (e) {
        console.error('predaniOpMeziOz', e.response?.data);
      }
    },
    changeAgreePredat(checked) {
      this.agreePredat = checked === true;
    },
    closePredaniModal() {
      this.agreePredat = false;
      this.$bvModal.hide('predani-op-modal');
    },
    copyOP(item) {
      this.copyToClipboard(item.orderNumber);
    },
    copyToClipboard(text) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$snotify['success']('Text byl zkopírován do schránky');
    },
    getVerzeSoD(obchod) {
      return obchod?.smlouvaDilo?.verzeSoD ? _.lowerFirst(this.getTextByKey(obchod.smlouvaDilo.verzeSoD, VERZE_SOD)) : '';
    },
    checkIsFactura(obchod) {
      return obchod?.smlouvaDilo?.verzeSoD === VERZE_SOD_TYPE.FAKTURA;
    },
    async changeOrder(ctx) {
      this.filter.sortBy = ctx.sortBy;
      this.filter.sortDirection = ctx.sortDesc;
      this.offset = 0;
      this.resetScrollTop();
      await this.getInfo(true);
    },
  },

  async mounted() {
    this.isLoading = true;
    this.filterType = FILTER_TYPES.NAZEV_V_ADRESARE;
    await this.getConstants();
    await this.getManagersKoordinators();
    this.checkQueryParams();

    await Promise.all([
      this.getInfo(),
      this.getOzUsersGroupsForUser(),
      this.getOkresy(),
    ]);

    const table = this.$el?.querySelector('.table-responsive');
    if (table) {
      table.addEventListener('scroll', this.handleScroll);
    }
    this.isLoading = false;
  },
  beforeDestroy() {
    if (typeof this.$el === 'object' && this.$el !== null && 'querySelectorAll' in this.$el) {
      this.$el.querySelector('.table-responsive').removeEventListener('scroll', this.handleScroll);
    }
  },
}
</script>

<style scoped>
.rotate > div {
  transform: rotate(-90deg);
}

.bg-color-white {
  background-color: white;
}

.text-black {
  color: black;
}

.table-bordered th, .table-bordered td {
  border: 1px solid black !important;
}

.danger-predat {
  border: 1px solid red;
  padding: 5px 7px;
  position: relative;
  top: -6px
}

</style>
